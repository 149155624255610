<template>
  <div class="pre-body pre-body-sm">
    <img class="pre-icon" src="/assets/images/login.png" alt="" />

    <div class="pre-divider"></div>

    <h3>Reset password</h3>

    <error-alert v-if="error">
      Email address was not found. Please register.</error-alert
    >

    <b-alert show variant="success" class="mb-5 p-4" v-if="sentEmail">
      Please check your email to reset your password.
    </b-alert>

    <b-form-group label="Enter your email">
      <pnw-input
        v-model="form.email"
        :state="isValidEmail"
        type="email"
        placeholder="name@email.com"
        autocomplete="username"
      ></pnw-input>
      <b-form-invalid-feedback> Enter a valid email </b-form-invalid-feedback>
    </b-form-group>
    <div class="pre-pad"></div>

    <div class="pre-divider"></div>

    <div class="pre-pad"></div>

    <div class="pre-form--group">
      <div class="pre-btns centered">
        <pnw-button
          @click="login"
          size="xl"
          variant="primary"
          :disabled="!form.email || sending"
        >
          Send email</pnw-button
        >
      </div>
      <div class="pre-btns centered">
        <router-link
          :to="{ name: $routerItems.LOGIN }"
          class="pre-btn pre-btn-underline"
        >
          <div class="pre-btn--copy">Login</div>
        </router-link>
        <router-link
          :to="{ name: $routerItems.REGISTER }"
          class="pre-btn pre-btn-underline"
        >
          <div class="pre-btn--copy">Create an account</div>
        </router-link>
      </div>
    </div>
  </div>
</template>
<script>
import validations from "../../utils/validations";
import ErrorAlert from "../../components/ErrorAlert.vue";
import { mapGetters } from "vuex";
import PnwButton from "../../components/PnwButton.vue";
import PnwInput from "../../components/PnwInput.vue";
import http from "../../services/HttpService";
import playSound from "../../services/AudioService";

export default {
  name: "Login",
  components: {
    ErrorAlert,
    PnwButton,
    PnwInput,
  },
  data() {
    return {
      form: {
        email: "",
      },
      sending: false,
      error: null,
      isValidEmail: null,
      sentEmail: false,
    };
  },
  methods: {
    async login() {
      this.sending = true;
      this.error = null;
      this.sentEmail = false;
      try {
        await http.post("/api/pnw/forgot-password", {
          email: this.form.email,
        });
        this.sentEmail = true;
      } catch (err) {
        playSound("GF-SpiritNet-Error.wav");
        this.error = true;
      }
      this.sending = false;
    },
  },
  watch: {
    "form.email": function (newValue) {
      this.isValidEmail = validations.isValidEmail(newValue);
    },
  },
  computed: {
    ...mapGetters({
      currentProfile: "currentProfile",
    }),
  },
};
</script>
<style lang="scss" scoped>
@import "~@/assets/scss/colors";
.auth-form {
  max-width: 600px;
  .forgot-password {
    font-size: 16px;
    line-height: 16px;
    font-weight: 700;
    line-height: 1;
    vertical-align: top;
    margin-bottom: 8px;
    padding-bottom: 0;
    color: $accent-02;
    text-shadow: 2px 0 0 blue, -1px 0 0 red;
  }
}
</style>
